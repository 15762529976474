import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

const Footer = () => (
  <footer>
    <Container>
      <Typography style={ { marginBottom: 20 } }>Copyright &copy; Biserica Penticostală Agape Helsingborg</Typography>
    </Container>
  </footer>
)

export default Footer
