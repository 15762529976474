import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import VisionPage from './pages/VisionPage'
import ContactPage from './pages/ContactPage'
import MediaPage from './pages/MediaPage'
import './App.css'

function App () {
  return (
    <Router>
      <div className='App'>
        <Header />

        <header className='App-header'>
          <Switch>
            <Route path='/' component={ HomePage } exact />
            <Route path='/about-us' component={ AboutPage } />
            <Route path='/vision' component={ VisionPage } />
            <Route path='/media' component={ MediaPage } />
            <Route path='/contact' component={ ContactPage } />
          </Switch>
        </header>
        <Footer />
      </div>
    </Router>
  )
}

export default App
