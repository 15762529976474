import { CHANGE_LANGUAGE } from '../actions/actionTypes/languageActionTypes'

const INITIAL_STATE = { language: 'romanian' }

const languageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, language: action.payload }
    default:
      return state
  }

}

export default languageReducer
