import { Box, Container, Paper, Typography } from '@mui/material'
import HouseIcon from '@mui/icons-material/House'
import EmailIcon from '@mui/icons-material/Email'

const ContactPage = () => {
  const ww = window.innerWidth

  return (
    <Container>
      <Typography
        variant={ `${ ww < 500 ? 'h5' : 'h4' }` }
        marginTop={ ww < 600 ? 15 : 0 }
        marginBottom={ ww < 600 ? 5 : 10 }
      >
        Cum ne puteți contacta
      </Typography>

      <Box
        sx={ {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          '& > :not(style)': {
            m: ww < 600 ? 3 : 5,
            width: '22rem',
            height: '16rem',
            paddingTop: ww > 500 ? 5 : 2.2,
            paddingLeft: ww > 500 ? 5 : 2.2,
            paddingRight: ww > 500 ? 5 : 2.2,
          },
        } }
      >
        <Paper elevation={ 3 }>
          <HouseIcon style={ { fontSize: '4.5rem', color: '#0a5573' } } />

          <Typography variant='h5' marginBottom={ 5 }>Locație</Typography>
          <Typography variant='h6'>
            <a href='https://www.google.com/maps/place/Bruksgatan+27,+252+24+Helsingborg,+Sweden/@56.0439899,12.6954124,17z/data=!3m1!4b1!4m5!3m4!1s0x4652324a93e76007:0xe037fb120d8e96be!8m2!3d56.0439869!4d12.6976011' target='_blank' rel='noopener noreferrer' style={ { color: '#000' } }>Bruksgatan 27,
              25224 Helsingborg</a>
          </Typography>
        </Paper>

        <Paper elevation={ 3 }>
          <EmailIcon style={ { fontSize: '4.5rem', color: '#0a5573' } } />

          <Typography variant='h5' marginBottom={ 5 }>Email</Typography>
          <Typography variant='h6'>
            <a href='mailto:biserica.agapehelsingborg@gmail.com' style={ { color: '#000' } }>
              biserica.agapehelsingborg
              @gmail.com
            </a>
          </Typography>
        </Paper>
      </Box>
    </Container>
  )
}

export default ContactPage
