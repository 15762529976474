export const data = [
  {
    marturisireaDeCredinta: {
      titlu: 'MĂRTURISIREA DE CREDINŢĂ',
      text: 'Biserica Penticostală Agape Helsingborg acceptă Sfânta Scriptură – Biblia, Cuvântul revelat şi inspirat al voii lui Dumnezeu ca normă atotsuficientă de credinţă şi practică, adoptând ca fundamentale, următoarele principii:'
    },
    puncte: [
      {
        titlu: '1. SINGURUL DUMNEZEU ADEVĂRAT',
        continutInitial: 'Singurul Dumnezeu adevărat S-a revelat pe Sine Însuşi ca etern, autoexistent „Eu sunt”, Creator al Cerului şi al Pământului şi Răscumpărător al omenirii. Mai mult decât atât, El S-a descoperit pe Sine Însuşi ca întruchipare a principiilor de relaţie şi uniune a trei persoane: Dumnezeu Tatăl, Dumnezeu Fiul şi Dumnezeu Duhul Sfânt, coexistente, consubstanțiale şi coerente, egale în dreptate, înţelepciune, putere, bunătate şi sfinţenie (Deuteronom 6:4; Isaia 43:10-11; Matei 28:19; Luca 3:22).',
        subPuncte: [],
        continutFinal: '',
      },
      {
        titlu: '2. DIVINITATEA ŞI UMANITATEA DOMNULUI ISUS HRISTOS',
        continutInitial: 'Domnul Isus Hristos este Fiul lui Dumnezeu. Scriptura declară:',
        subPuncte: [
          { titluSubpunct: 'a. Eternitatea Domnului Isus (Ioan 1:1; Mica 5:2)' },
          { titluSubpunct: 'b. Naşterea Domnului Isus (Matei 1:23; Luca 1:31,35)' },
          { titluSubpunct: 'c. Umanitatea Domnului Isus (Ioan 4)' },
          { titluSubpunct: 'd. Viaţa Sa fără păcat (Evrei 7:26; 1 Petru 2:22)' },
          { titluSubpunct: 'e. Minunile Sale (Fapte 2:22; 10:38)' },
          { titluSubpunct: 'f. Lucrarea Sa de substituire pe Cruce (1 Cor. 15:3; 2 Cor. 5:21)' },
          { titluSubpunct: 'g. Învierea Sa din morţi, în trup (Matei 28:6; Luca 24:39; 1 Cor. 15:4)' },
          { titluSubpunct: 'h. Înălţarea Sa la dreapta lui Dumnezeu (Fapte 1:9,11; 2:33; Filipeni 2:9-11; Evrei 1:3)' },
          { titluSubpunct: 'i. Revenirea ca Judecător (Fapte 17:31)' },
        ],
        continutFinal: 'Hristos S-a născut Om prin Sfânta Fecioară, unind ambele naturi, Dumnezeu şi om într-o singură persoană, niciodată divizată, fiind un singur Hristos, Dumnezeu adevărat şi Om perfect, a suferit, a fost crucificat, a murit, a fost îngropat, a înviat, şi a fost înălţat, pentru a ne împăca cu Tatăl şi a făcut ispăşire atât pentru păcatul originar cât şi pentru păcatele comise.',
      },
      {
        titlu: '3. PERSOANA DUHULUI SFÂNT',
        continutInitial: 'Duhul Sfânt vine de la Tatăl şi de la Fiul şi este Dumnezeu veşnic şi adevărat.',
        subPuncte: [],
        continutFinal: '',
      },
      {
        titlu: '4. SFÂNTA SCRIPTURĂ',
        continutInitial: 'Scrierile Scripturii, atât Vechiul cât şi Noul Testament (66 de cărţi) sunt inspirate de Dumnezeu şi reprezintă revelaţia lui Dumnezeu pentru om, norme de credinţă şi conduită infailibile, autoritare şi demne de încredere (2 Timotei 3:15-17; 1 Tesaloniceni 2:13; 2 Petru 1:21).',
        subPuncte: [],
        continutFinal: '',
      },
      {
        titlu: '5. OMUL',
        continutInitial: 'Omul a fost creat faptură inocentă, dupa chipul şi asemănarea lui Dumnezeu. Prin neascultare şi încălcarea voluntară a poruncii lui Dumnezeu, omul a căzut, aceasta atrăgând după sine moartea fizică şi moartea spirituală, care înseamnă separarea de Dumnezeu (Geneza 1:26-27; 2:17; 3:6; Romani 5:12-19).',
        subPuncte: [],
        continutFinal: '',
      },
      {
        titlu: '6. MÂNTUIREA OMULUI',
        continutInitial: 'Singura cale de mântuire şi răscumparare a omului este prin jertfa ispăşitoare a Domnului Isus Hristos, Fiul lui Dumnezeu (Romani 3:25).',
        subPuncte: [
          {
            titluSubpunct: 'a. Condiţii pentru mântuire.',
            continutSubpunct: 'Mântuirea se primeşte prin pocăinţă înaintea lui Dumnezeu şi prin credinţă în Domnul Isus Hristos, prin spălarea naşterii din nou şi prin înnoirea făcută de Duhul Sfânt. Fiind justificat prin har, prin credintă, omul devine moştenitor al Împărăţiei lui Dumnezeu şi al vieţii veşnice (Luca 24:47; Ioan 3:3; Romani 10:13-15; Efeseni 2:8: Tit 2:11; 3:5-7).',
          },
          {
            titluSubpunct: 'b. Evidenţa mântuirii.',
            continutSubpunct: 'Evidenţa interioară a mântuirii este mărturia directă a Duhului (Romani 8:16). Evidenţa  exterioară este o viaţă de neprihănire şi sfinţenie (Efeseni 4:24; Tit 2:12).',
          },
        ],
        continutFinal: '',
      },
      {
        titlu: '7. SFINŢIREA',
        continutInitial: 'Sfinţirea începe odată cu înnoirea făcută de Duhul Sfant şi este desăvârşită în glorificare. Aceasta este lucrarea instantanee a harului, dar şi o atitudine continuă de separare de tot ceea ce este rău şi de dedicare lui Dumnezeu, reflectată în trăirea unei vieţi curate (Romani 12:1-2; 1 Tesaloniceni 5:22; Evrei 13:12). Scriptura ne învaţă să trăim „o viață de sfinţire, fără de care nimeni nu va vedea pe Domnul” (Evrei 12:14). Prin puterea Duhului Sfânt noi ascultăm de porunca lui Dumnezeu: „Fiţi sfinţi căci şi Eu sunt sfânt” (1 Petru 1:15-16; Romani 6:1-13; 8:1-2,13-14; Galateni 2:20; Filipeni 2:12,13; 2 Petru 1:5).',
        subPuncte: [],
        continutFinal: '',
      },
      {
        titlu: '8. BOTEZUL ÎN DUHUL SFÂNT',
        continutInitial: '',
        subPuncte: [
          {
            titluSubpunct: 'a. Consideraţii generale.',
            continutSubpunct: 'Toți credincioşii trebuie să dorească cu înflăcărare şi să caute cu deplină sinceritate şi convingere făgăduinţa Tatălui, botezul în Duhul Sfânt, conform promisiunii Domnului Isus Hristos. Scopurile Botezului în Duhul Sfânt sunt: împuternicirea pentru lucrare, acordarea darurilor şi folosirea lor în lucrarea de slujire (Luca 24:49; Fapte 1:4,8; 1 Corinteni 12:1-31), precum şi o consacrare sporită pentru Dumnezeu, o dedicare deosebită pentru lucrarea Lui (Fapte 2:43; Evrei 12:28) şi o dragoste activă pentru Hristos, prin Cuvântul Său şi pentru cei pierduţi (Marcu 16:20). Botezul în Duhul Sfânt este o experienţă distinctă şi ulterioară naşterii din nou (Fapte 8:12-17; 10:44-46; 11:15-16; 15:7-9).',
          },
          {
            titluSubpunct: 'b. Evidenţa iniţială a botezului în Duhul Sfânt',
            continutSubpunct: 'Botezul credincioşilor în Duhul Sfânt este evidenţiat prin vorbirea în alte limbi după cum dă Duhul (Fapte 2:4). Vorbirea în limbi este manifestarea darului vorbirii în felurite limbi (1 Corinteni 12:4-10,28).',
          },
        ],
        continutFinal: '',
      },
      {
        titlu: '9. BISERICA',
        continutInitial: 'Biserica este Trupul lui Hristos, unde locuieşte Dumnezeu prin Duhul Sfânt, formată din totalitatea oamenilor născuţi din nou. Biserica locală este expresia vizibilă a Bisericii universale. Scopul întreit al bisericii este închinarea, edificarea şi multiplicarea.',
        subPuncte: [],
        continutFinal: '',
      },
      {
        titlu: '10. OFICIILE BISERICII',
        continutInitial: '',
        subPuncte: [
          {
            titluSubpunct: 'a. Botezul în apă.',
            continutSubpunct: 'Botezul în apă, prin scufundare, este poruncit de Scriptură. Toţi cei care se pocăiesc şi cred în Isus Hristos ca Mântuitor şi Domn personal şi au experimentat naşterea din nou urmează să fie botezaţi în Numele Tatălui, al Fiului şi al Sfântului Duh. Astfel, ei mărturisesc public că au fost inviaţi împreună cu Hristos la o viaţă nouă (Matei 28:19; Marcu 16:16; Fapte 10:47-48; Romani 6:4). Botezul în apă este  manifestarea vizibilă a intrării în Biserică.',
          },
          {
            titluSubpunct: 'b. Sfânta comuniune.',
            continutSubpunct: 'Cina Domnului, compusă din doua elemente – pâine şi rodul viţei, este simbolul care înfăţişează părtaşia noastră la natura divină a Domnului nostru Isus Hristos (2 Petru 1:4) şi o aducere aminte a suferinţelor şi morţii Sale (1 Corinteni 11:26), fiind poruncită tuturor credincioşilor „până va veni El!” Participarea la Cina Domnului aduce prin credintă binecuvantarea reală a actului simbolizat.',
          },
          {
            titluSubpunct: 'c. Spălarea picioarelor.',
            continutSubpunct: 'Domnul Isus a instituit actul spălării picioarelor (Ioan 13:14). Aceasta este amintită şi ca o condiţie de adevarată evlavie în viaţa de credinţă (1 Timotei 5:10).',
          },
        ],
        continutFinal: '',
      },
      {
        titlu: '11. VINDECAREA DIVINĂ',
        continutInitial: 'Vindecarea divină este parte integrată a Evangheliei. Eliberarea de boală este posibilă ca efect al ispăşirii, fiind accesibilă tuturor credincioşilor (Isaia 53:4-5; Matei 8:16-17; Iacov 5:14-16).',
        subPuncte: [],
        continutFinal: '',
      },
      {
        titlu: '12. LUCRURILE DIN URMĂ',
        continutInitial: '',
        subPuncte: [
          {
            titluSubpunct: 'a. Speranţa binecuvantată.',
            continutSubpunct: 'Învierea din morţi a celor care au adormit în Hristos şi strămutarea lor împreuna cu cei care vor fi încă în viaţă la venirea Domnului, este speranţa iminentă şi binecuvântată a Bisericii (1 Tesaloniceni 4:16-17; Romani 8:23; Tit 2:13; 1 Corinteni 15:51-52). A doua venire a lui Hristos include răpirea sfinţilor, urmată de reîntoarcerea vizibilă a lui Hristos pentru a domni împreună cu toţi sfinţii Săi (Zaharia 14:5; Matei 24:27,30; Apocalipsa 1:7; 19:11-14; 20:1-6).',
          },
          {
            titluSubpunct: 'b. Judecata finală.',
            continutSubpunct: 'Va exista o judecată finală prin care cei răi vor fi judecati. Toţi cei care nu vor fi găsiţi scrişi în Cartea Vieţii vor fi încredinţaţi pedepsei veşnice în iazul care arde cu foc şi pucioasă, împreună cu diavolul şi îngerii lui, cu fiara şi prorocul mincinos, aceasta fiind moartea a doua (Matei 25:46; Marcu 9:43-48; Apocalipsa 19:20; 20:11-15; 21:8).',
          },
          {
            titluSubpunct: 'c. Cerul nou şi pământul nou.',
            continutSubpunct: 'Viaţa veşnică împreună cu Dumnezeu este o parte din rasplata finală a celor răscumparati. „Dar noi, după făgăduinţa Lui, aşteptăm ceruri noi şi un pământ nou, în care va locui neprihănirea”(2 Petru 3:13; Apocalipsa 21:22).',
          },
        ],
        continutFinal: '',
      },
      {
        titlu: '13. MAREA TRIMITERE',
        continutInitial: 'Este responsabilitatea fiecărui credincios să-şi dedice viaţa pentru împlinirea Marii Trimiteri pe care Domnul Isus a dat-o Bisericii Sale (Matei 28:18-20; Marcu 16:15-20; Fapte 1:8).',
        subPuncte: [],
        continutFinal: '',
      },
    ],
  }
]
