import { Container, ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material'
// import { useSelector } from 'react-redux'

const listaFrati = [
  {
    poza: 'Francisc_Ötvös.PNG',
    nume: 'Francisc Ötvös',
    numberDeTelefon: '070-739 01 25',
    pozitia: 'Prezbiter',
  },
  {
    poza: 'Dacian_Anton.PNG',
    nume: 'Dacian Anton',
    numberDeTelefon: '070-748 29 69',
    pozitia: 'Diacon',
  },
  {
    poza: 'Petru_Rad.PNG',
    nume: 'Petru Rad',
    numberDeTelefon: '070-999 90 41',
    pozitia: 'Diacon',
  },
  {
    poza: 'Vasile_Radu.jpeg',
    nume: 'Vasile Radu',
    numberDeTelefon: '073-760 29 39',
    pozitia: 'Diacon',
  },
  {
    poza: 'Valentin_Crețu.PNG',
    nume: 'Valentin Crețu',
    numberDeTelefon: '070-033 34 38',
    pozitia: 'Diacon',
  },
  {
    poza: 'Samuel_Dumitru.jpg',
    nume: 'Samuel Dumitru',
    numberDeTelefon: '072-914 09 17',
    pozitia: 'Lider de tineret',
  },
  {
    poza: 'Beniamin_Rad.PNG',
    nume: 'Beniamin Rad',
    numberDeTelefon: '073-952 68 98',
    pozitia: 'Lider de tineret și casierul Bisericii',
  },
]

const HomePage = () => {
  // const { language } = useSelector(state => state.selectedLanguage)
  const ww = window.innerWidth

  return (
    <Container>
      <div style={ { marginTop: ww > 600 ? 140 : 90 } }>
        <div style={ { display: 'flex', flexDirection: ww > 600 ? 'row' : 'column' } }>
          <img src='poze_petrisor/1d7478d3-7eda-4e33-b345-21bddbb7afe2.jpg' alt='Holy bible' height={ ww > 600 ? 600 : 500 } />
          <img style={ { position: 'absolute', top: -60 } } src='agape_church_logo_large_white.svg' alt='Holy bible' width={ 100 } height={ ww > 600 ? 500 : 400 } />

          <Typography
            variant='h5'
            marginTop={ ww > 600 ? 25 : 2 }
            display='inline'
            ml={ ww > 600 && 10 }
          >
            "Căci sunt bine încredinţat că nici moartea, nici viaţa, nici îngerii, nici stăpânirile, nici puterile, nici lucrurile de acum, nici cele viitoare, nici înălţimea, nici adâncimea, nici o altă făptură nu vor fi în stare să ne despartă de dragostea lui Dumnezeu, care este în Isus Hristos, Domnul nostru." – Romani 8:38-39
          </Typography>
        </div>

        <Typography
          variant={ `${ ww < 500 ? 'h5' : 'h4' }` }
          marginTop={ ww > 600 ? 10 : 15 }
        >
          Echipa de conducere
        </Typography>

        <ImageList cols={ ww < 500 ? 1 : 3 } gap={ 30 }>
          { listaFrati.map((frate, index) => (
            <ImageListItem key={ index } >
              <img
                src={ `./fratii_din_conducere/${ frate.poza }` }
                alt={ `${ frate.nume }` }
                loading='lazy'
                style={ {
                  objectFit: 'contain',
                  maxHeight: ww > 500 && 350,
                } }
              />

              <ImageListItemBar
                title={ frate.nume }
                subtitle={ <div>
                  <p style={ { fontSize: 16, marginTop: 5 } }>{ frate.pozitia }</p>
                  <a
                    href={ `tel:${ frate.numberDeTelefon }` }
                    style={ { fontSize: 15, color: '#000' } }
                  >
                    Telefon: { frate.numberDeTelefon }
                  </a>
                </div> }
                position='below'
              />
            </ImageListItem>
          )) }
        </ImageList>
      </div>
    </Container >
  )
}

export default HomePage
