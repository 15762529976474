export const valori_ro = [
  {
    valoare: '1. Lărgirea Împărăției lui Dumnezeu',
    subValoare: 'Prin botez si ucenicizare',
    verset: 'Matei 28:19-20',
  },
  {
    valoare: '2. Manifestarea prezenţei și libertatea Duhului Sfânt',
    subValoare: '',
    verset: 'Exod 40:34; Fapte 1:8; 1 Cor. 12:4-11',
  },
  {
    valoare: '3. Împlinirea Cuvântului transformator',
    subValoare: 'Logos şi Rhema',
    verset: '2 Tim. 3:16; lacov 1:22; Evrei 4:12; 1 Cor. 2:4',
  },
  {
    valoare: '4. Înălţăm rugăciuni fierbinţi',
    subValoare: 'Cu putere, cu pasiune, înflacărate, mijlocire şi luptă spirituală.',
    verset: 'Iacov 5:16; Ezechiel 22:30; Rom. 8:26; Efes. 6:10-13',
  },
  {
    valoare: '5. Aducem laudă şi închinare profundă',
    subValoare: '',
    verset: 'Ioan 4:23-24; Psalmul 150; Psalmul 66:8; Isaia 40:9',
  },
  {
    valoare: '6. Echipăm credincioşii',
    subValoare: 'Încurajăm, ucenicizăm, mentorăm, creăm un climat pentru creștere spirituală.',
    verset: 'Matei 28:19; Efes. 4:12-15; 2 Tim. 2:2',
  },
  {
    valoare: '7. Slujim împreună, cu excelenţă',
    subValoare: 'Fiecare credincios, un slujitor!',
    verset: 'Marcu 10:43-45; Col. 3:23-24; Efes. 4:16; 1 Petru 4:10-11',
  },
  {
    valoare: '8. Dezvoltăm relaţii sănătoase',
    subValoare: 'Ne dorim familii puternice, relații dumnezeiești între noi și cu cel din afara Bisericii.',
    verset: '1 Tim. 1:5; Marcu 12:31; loan 13:34-35; Rom. 12:10',
  },
  {
    valoare: '9. Promovăm unitatea în diversitate',
    subValoare: '',
    verset: 'Psalmul 133; loan 17:21; Rom. 12:4-5; 1 Cor. 1:10; Fil. 3:15-16',
  },
  {
    valoare: '10. Dăruim cu bucurie',
    subValoare: 'Binecuvântat pentru a fi o binecuvântare!',
    verset: 'Maleahi 3:10-11; Luca 6:38; 2 Cor. 9:7',
  },
  {
    valoare: '11. Susţinem integritatea',
    subValoare: 'Caracter cristic şi exemplu personal.',
    verset: 'Rom. 12-2; Matei 5:37; 1 Tim. 4:12; 2 Tim. 2:15-16',
  },
  {
    valoare: '12. Încurajăm ascultarea şi supunerea',
    subValoare: 'Ascultarea face mai mult decât jertfele!',
    verset: '1 Samuel 15:22; Fil. 2:12-16; Evrei 13:17',
  },
  {
    valoare: '13. Transmitem mai departe',
    subValoare: 'Împărtăşim / arătăm dragostea lui Dumnezeu celor din jurul nostru!',
    verset: 'Matei 25:35-40; Rom. 10:14-15; 1 Petru 2:12',
  },
]
