import { Container } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import { valori_ro } from '../viziune_ro'

const VisionPage = () => {
  const ww = window.innerWidth

  return (
    <Container>
      <div style={ { marginTop: ww > 600 ? 100 : 70 } }>
        <div style={ { display: 'flex', flexDirection: ww > 600 ? 'row' : 'column' } }>
          <img src='agape_church_logo_large.svg' alt='Holy bible' width={ ww > 600 ? 500 : 400 } height={ ww > 600 ? 500 : 400 } />

          <Typography
            variant='h5'
            marginTop={ ww > 600 ? 25 : 2 }
            display='inline'
          >
            În Biserica Penticostală Agape Helsingborg punem accentul pe dragoste

            <Typography style={ { marginTop: 10 } }>Ioan 15:12-13; 1 Cor. 16:14; Filipeni 1:9; 1 Ioan 3:11</Typography>
          </Typography>
        </div>
      </div>

      <Typography
        variant={ `${ ww < 500 ? 'h5' : 'h4' }` }
        marginTop={ ww < 600 ? 5 : 10 }
      >
        Viziunea Noastră
      </Typography>

      <Box
        sx={ {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          margin: ww < 600 ? 0 : 4,
          '& > :not(style)': {
            m: 3,
            width: 250,
            height: 200,
          },
        } }
      >
        { valori_ro.map((valoare, index) =>
          <Paper key={ index } elevation={ 3 } style={ { backgroundColor: '#dbdbdb', padding: 30 } }>
            <Typography variant={ ww < 600 ? 'h5' : 'h6' } gutterBottom>{ valoare.valoare }</Typography>
            <Typography variant={ ww < 600 ? 'body1' : 'subtitle1' } gutterBottom>{ valoare.subValoare }</Typography>
            <Divider style={ { marginTop: 10, marginBottom: 10 } } />
            <Typography variant='body1'>{ valoare.verset }</Typography>
          </Paper>) }
      </Box>
    </Container>
  )
}

export default VisionPage
