import { AppBar, Toolbar, Button, /* FormControl, InputLabel, Select, MenuItem,  */Container } from '@mui/material'
import { /* useDispatch, */ useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
// import { changeLanguage } from '../actions/languageActions'

const Header = () => {
  // const dispatch = useDispatch()

  const { language } = useSelector(state => state.selectedLanguage)

  return (
    <AppBar position='fixed' style={ { backgroundColor: '#0a5573' } }>
      <Toolbar>
        <Container maxWidth='md' style={ { display: 'flex', justifyContent: 'space-between' } }>
          <Button component={ Link } to='/' color='inherit'>
            <img src='agape_church_logo_large.svg' alt='Holy bible' width={ 50 } />
            { language === 'romanian' ? 'Acasă' : 'Hem' }
          </Button>
          <Button component={ Link } to='/about-us' color='inherit'>{ language === 'romanian' ? 'Despre noi' : 'Om oss' }</Button>
          <Button component={ Link } to='/vision' color='inherit'>Viziune</Button>
          <Button component={ Link } to='/media' color='inherit'>Media</Button>
          <Button component={ Link } to='/contact' color='inherit'>{ language === 'romanian' ? 'Contact' : 'Kontakt' }</Button>

          {/* <FormControl sx={ { m: 1, minWidth: 120 } }>
            <InputLabel id='select-label'>Language</InputLabel>
            <Select
              labelId='select-label'
              id='select'
              value={ language }
              label='Language'
              onChange={ e => dispatch(changeLanguage(e.target.value)) }
            >
              <MenuItem value='romanian'>Romanian</MenuItem>
              <MenuItem value='swedish'>Swedish</MenuItem>
            </Select>
          </FormControl> */}
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default Header
