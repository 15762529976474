import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { data } from '../marturisirea_de_credinta_ro.js'

const AboutPage = () => (
  <>
    <img src='holy-bible.jpg' alt='Holy bible' width='100%' />

    <Container maxWidth='sm'>
      <Box sx={ { marginTop: 5, marginBottom: 5 } }>
        <Typography variant='h4' gutterBottom>{ data[0].marturisireaDeCredinta.titlu }</Typography>
        <Typography variant='body1' gutterBottom>{ data[0].marturisireaDeCredinta.text }</Typography>

        { data[0].puncte.map((item, firstIndex) =>
          <Box key={ firstIndex } mt={ 8 }>
            <Typography variant='h5' gutterBottom>{ item.titlu }</Typography>
            <Typography variant='body1' gutterBottom>{ item.continutInitial }</Typography>

            { item.subPuncte.map((subPunct, index) =>
              <Box key={ index }>
                <Typography variant='body1' fontWeight={ 500 } mt={ firstIndex > 2 && 3 } gutterBottom>{ subPunct.titluSubpunct }</Typography>
                <Typography variant='body1' gutterBottom>{ subPunct.continutSubpunct }</Typography>
              </Box>
            ) }

            <Typography variant='body1' gutterBottom>{ item.continutFinal }</Typography>
          </Box>
        ) }
      </Box>
    </Container>
  </>
)

export default AboutPage
