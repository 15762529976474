import { Container, Typography } from '@mui/material'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'

const importAll = r => r.keys()
const images = importAll(require.context('../../public/poze_petrisor', false, /\.(png|jpe?g|svg)$/i)).map(image => image.replace('./', ''))

const MediaPage = () => {
  const ww = window.innerWidth

  return (
    <Container maxWidth='xl'>
      <Typography
        variant={ `${ ww < 500 ? 'h5' : 'h4' }` }
        marginTop={ ww < 600 ? 15 : 20 }
        marginBottom={ ww < 600 ? 5 : 10 }
      >
        Poze de le evenimentele Bisericii
      </Typography>

      <ImageList cols={ ww < 500 ? 1 : 3 } gap={ 30 }>
        { images.map((image, index) => (
          <ImageListItem key={ index }>
            <img
              src={ `./poze_petrisor/${ image }` }
              alt='Poza eveniment Biserică'
              loading='lazy'
              style={ {
                objectFit: 'contain',
                maxHeight: ww > 500 && 500,
              } }
            />
          </ImageListItem>
        )) }
      </ImageList>
    </Container >
  )
}

export default MediaPage
